import React from 'react';

export default function Progress (props) {
  return (
    <div className="ProgressBar">
      <div
        className="Progress"
        style={{ width: props.progress + '%' }}
      />
    </div>
  )
}