import fetch from 'cross-fetch';
import { baseUrl } from './baseUrl';

/*
 * Simulate loading time with a delay
 */
const delay = 500;

export default function fetchFilelist() {
  return fetch(`${baseUrl}api/files/?format=json`, {
    // mode: 'cors', // no-cors, *cors, same-origin
    credentials: 'same-origin', // include, *same-origin, omit
    headers: {
      'Content-Type': 'application/json'
    },
  })
    .then(response => {
        if (response.ok) {
          return response;
        } else {
          var error = new Error('Error ' + response.status + ': ' + response.statusText);
          error.response = response;
          throw error;
        }
      },
      error => {
        throw new Error(error.message);
      })
    .then(
      response => response.json()
    )
    .then(
      response => {
        return new Promise((resolve, reject) => {
          setTimeout(() => {
            resolve(response)
          }, delay)
        })
      }
    )
}