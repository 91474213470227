/**
 * The backend server URL is dependent upon the deployment URL of the React acpp
 * @type {string} baseUrl
 */
export let baseUrl;
if (/stefan00.com/.test(window.location.origin)) {
  baseUrl = 'https://csvbackend.stefan00.com/';
} else if (/stefanzero.com/.test(window.location.origin)) {
  baseUrl = 'https://csvbackend.stefanzero.com/';
} else {
  /*
   * Local deployment assumes standard Django development server is run on default port
   */
  baseUrl = 'http://localhost:8000/';
}

// baseUrl = 'https://csvbackend.stefanzero.com/';
