import React from 'react';
import {
  Breadcrumb, BreadcrumbItem, Card, CardBody, CardHeader, CardTitle, Button, Table
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { Loading } from './Loading';
import { Pagination } from './Pagination';
import { pageLength } from '../shared/pageLength';


export default function Fileviewer(props) {

  const clickPage = function(event) {
    const classList = Array.from(event.target.classList);
    const page = Number(classList.filter(c => /pagenum/.test(c))[0].slice(8));
    // console.log('clickPage', page);
    props.handlePage(page);
  };

  if (props.isLoading && props.filename) {
    return (
      <div className="container">
        <div className="row">
          <Loading/>
        </div>
      </div>
    );
    } else if (props.errorMessage) {
      return (
        <div className="container">
          <div className="row">
            <h4>{props.errorMessage}</h4>
          </div>
        </div>
      );
  } else {
    const filerows = props.filerows;
    const length = filerows.length;
    const pages = Math.ceil(length / pageLength);
    const page = props.page || 1;
    const headings = length ? filerows[0].split(',') : [];
    let stateColumn = null;
    if (headings.length) {
      stateColumn = headings.findIndex(h => /^state$/i.test(h))
    }
    const pageIndex = (page - 1) * pageLength + 1;
    console.log('pageIndex', pageIndex);
    const pageRows = length ? filerows.slice(pageIndex, pageIndex + pageLength + 1) : [];
    return (
      <div className="container-fluid fileviewer">
        <div className="row row-header">
          <Breadcrumb>
            <BreadcrumbItem><Link to="/home">Home</Link></BreadcrumbItem>
            <BreadcrumbItem active>File Viewer</BreadcrumbItem>
          </Breadcrumb>
          <div className="col-12 d-flex">
            <h3 className="d-inline mr-auto">File Viewer</h3>
            <Link to="/filelist">
              <Button color="primary d-inline-block ml-auto">
                Select new file from list
              </Button>
            </Link>
          </div>
          <hr />
        </div>
        <div className="row row-content">
          <div className="col-12">
            { length > 0 && props.filename !== '' &&
            (
              <Card>
                <CardHeader>
                  <CardTitle>
                    <strong>{props.filename}</strong>
                  </CardTitle>
                  <Pagination page={page} pages={pages} clickPage={clickPage} />
                </CardHeader>
                <CardBody>
                  <Table striped>
                    <thead><tr>
                      {
                        headings.map((h, i) => {
                          return (
                            <th key={i}>{h}</th>
                          )
                        })
                      }
                    </tr></thead>
                    <tbody>
                    {
                      pageRows.map((r, i) => {
                        const values = r.split(',');
                        return (
                          <tr key={i}>
                            {
                              values.map((d, i) => {
                                /*
                                 * If state is an empty string, replace it with BLANK
                                 */
                                if ((i === stateColumn) && (d === '')) {
                                  d = 'BLANK';
                                }
                                return (
                                  <td key={i}>{d}</td>
                                )
                              })
                            }
                          </tr>
                        )
                      })
                    }
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            )}
          </div>
        </div>
      </div>
    )
  }
}
