import React, { Component } from 'react';
import {
  Breadcrumb, BreadcrumbItem,
  Card, CardBody, CardHeader, CardTitle,
  Button,
  Table,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { baseUrl } from '../shared/baseUrl';
import { Loading } from "./Loading";


export default class Filelist extends Component {

  constructor(props) {
    super(props);
    this.handleDowload = this.handleDowload.bind(this);
    console.log('Filelist constructor')
  }

  handleDowload(filename) {
    console.log(`Downloaded ${filename}`);
  }

  render() {
    const { props } = this;
    if (props.files.isLoading) {
      return(
        <div className="container">
          <div className="row">
            <Loading />
          </div>
        </div>
      );
    }
    else if (props.files.errorMessage) {
      return(
        <div className="container">
          <div className="row">
            <h4>{props.errorMessage}</h4>
          </div>
        </div>
      );
    }
    else {
      /*
      {
        "file_id":1,
        "file":"http://localhost:8000/media/small.csv",
        "since_added":"2020-08-25T09:53:57.460809Z",
        "size":1238,
        "name":"small.csv",
        "filetype":"csv"
      }
       */
      return (
        <div className="container filelist">
          <div className="row">
            <Breadcrumb>
              <BreadcrumbItem><Link to="/home">Home</Link></BreadcrumbItem>
              <BreadcrumbItem active>File List</BreadcrumbItem>
            </Breadcrumb>
            <div className="col-12">
              <h3>File List</h3>
              <hr/>
            </div>
          </div>
          <div className="row row-content">
            <div className="col-12">
              <Card>
                <CardHeader>
                  <CardTitle>
                    Number of files: {this.props.files.files.length}
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <Table striped>
                    <thead>
                    <tr>
                      <th>ID</th>
                      <th>Name</th>
                      <th>Size (Kb)</th>
                      <th>Date Uploaded</th>
                      <th>View</th>
                      <th>Download</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                      this.props.files.files.map((file, index) => {
                        const size = (file.size / 1000).toFixed(2);
                        return (
                          <tr key={index}>
                            <td>{file.file_id}</td>
                            <td>{file.name}</td>
                            <td>{size}</td>
                            <td>{new Date(file.since_added).toLocaleString()}</td>
                            <td>
                              <Button color="info" onClick={() => this.props.handleFileview(file.name)}>
                                View
                              </Button>
                            </td>
                            <td>
                              <Button color="success" onClick={() => this.handleDowload(file.name)}>
                                <a href={`${baseUrl}media/${file.name}`} target="_blank" download>
                                  Down<wbr />load
                                </a>
                              </Button>
                            </td>
                          </tr>
                        )
                      })
                    }
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </div>
          </div>
        </div>
      )
    }
  }
}