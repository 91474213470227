import React, { useEffect } from 'react';

import {
  Card, CardBody, CardHeader, CardTitle, CardSubtitle, Table
} from 'reactstrap';
import {baseUrl} from "../shared/baseUrl";

export function Assignment() {
  useEffect(() => {
    const hljs = window.hljs;
    window.document.querySelectorAll('pre code').forEach((block) => {
      hljs.highlightBlock(block);
    });
  });
  return (
    <React.Fragment>
      <Card className="Assignment">
        <CardHeader>
          <CardTitle>
            <h3>CSV Web Application</h3>
          </CardTitle>
        </CardHeader>
        <CardBody>
          <h4>Goal</h4>
          <p>Design a full stack web application to handle CSV data.</p>
          <h5>Purpose</h5>
          <p>The assignment is very open by design, the
            intent is to give you an opportunity to showcase your skills and demonstrate
            techniques you like to use. </p>
          <h5>Requirements</h5>
          <p>The application should have the ability to:</p>
          <ul>
            <li>Upload a CSV file.</li>
            <li>List uploaded CSV files.</li>
            <li>Download the previously uploaded CSV file.</li>
            <li>Display the CSV content showing at least all column headers and content.</li>
            <li>Provide statistics on the number of people with the same year in the "date" field.</li>
            <li>The application should be comprised of both a
              frontend and a backend. The frontend must have at least three buttons, allowing
              for upload download and date statistics.</li>
          </ul>
          <h5>Notes:</h5>
          <ul>
            <li>The application can be written in any language</li>
            <li>The CSV will be comma delimited</li>
            <li>The CSV file may contain empty string in the "state" column. In this case, fill in
              with the text "BLANK" instead</li>
            <li>The application should be able to run on any machine</li>
            <li>We do not impose restrictions on
              what technology stack you wish to use, however the technical stack we use is
              python backend framework and JS frontend framework</li>
            <li><span className="mr-2">Example CSV file here:</span>
              <a target='_blank' href="https://drive.google.com/file/d/1kxaz2n3f4mxJvo3kO_zHp0DJtbV8Ht7b/view?usp=sharing">
                <span style={{color: '#1155CC'}}>example.csv</span>
              </a>
            </li>
          </ul>
        </CardBody>
      </Card>
      <Card>
        <CardHeader>
          <CardTitle>
            <h5>Backend Application Solution</h5>
          </CardTitle>
          <CardSubtitle>
            <h3>Python Django Rest Framework</h3>
          </CardSubtitle>
        </CardHeader>
        <CardBody>
          <h4>Features</h4>
          <ul>
            <li>Well established highly-scalable back-end web application.</li>
            <li>Built-in Database Object Relational Mapper.</li>
            <li>Django REST Framework generates HTML pages to browse and execute all API endpoints.</li>
            <li>Many other built-in features such as authentication, permissions and throttling.</li>
          </ul>
        </CardBody>
      </Card>
      <Card>
        <CardHeader>
          <CardTitle>
            <h5>Frontend Application Solution</h5>
          </CardTitle>
          <CardSubtitle>
            <h3>React</h3>
          </CardSubtitle>
        </CardHeader>
        <CardBody>
          <h4>Features</h4>
          <ul>
            <li>The virtual DOM (short for Document Object Model) is the core reason why React enables the creation of fast, scalable web apps.</li>
            <li>React enables the creation of module-like reusable pieces of code called “Components”.</li>
            <li>React's unidirectional data flow enables web developers to pass properties from parent components to children components as immutable values.</li>
          </ul>
        </CardBody>
      </Card>
      <Card>
        <CardHeader>
          <CardTitle>
            <h4>Quick Start Guide</h4>
          </CardTitle>
        </CardHeader>
        <CardBody>
          <ul>
            <li>Clone the repo<br />
              <pre><code className="bash">git clone url</code></pre>
            </li>
            <li>Install the NPM Packages<br />
              <pre><code>
                cd csv-manager<br />
                npm install
              </code></pre>
            </li>
            <li>
              Install the&nbsp;
              <a href="https://csvbackend.stefanzero.com/" target="_blank">
                Python back-end project
              </a>, or edit the file <span className="my-code">shared/baseUrl.js</span> to use the
              deployed backend project:
              <pre><code className="javascript">
      baseUrl = 'https://csvbackend.stefanzero.com/';
              </code></pre>
            </li>
            <li>Start React
              <pre><code>npm start</code></pre>
            </li>
            <li>Open a browser at
              <pre><code>http://localhost:3000</code></pre>
            </li>
          </ul>
        </CardBody>
      </Card>
      <Card>
        <CardHeader>
          <CardTitle>
            <h4>Front-end Application Design</h4>
          </CardTitle>
        </CardHeader>
        <CardBody>
          <h5>Navigation Bar and Routes</h5>
          <ul>
            <li><strong>Home</strong>: web site description (this page)</li>
            <li><strong>File List</strong>: table of files uploaded, with buttons to view and download</li>
            <li><strong>File Upload</strong>: form with "browse" file input to upload</li>
            <li><strong>File Viewer</strong>: paginated table of CSV file contents</li>
            <li><strong>File Stats</strong>: table of file statistics, listing number of rows with a given year in the date column</li>
            <li><strong>Log In / Log Out Button</strong>: opens modal dialog</li>
          </ul>
          <hr />
          <h5>Login Modal</h5>
            <p>
              The Header component contains the Navigation Bar.  On the right end of the Navigation
              Bar is a Login / Logout button.  Clicking this button brings up a Login Modal, with
              a form for the <em>username</em> and <em>password</em>.
            </p>
            <p>
              The Modal has 4 buttons:
            </p>
          <ul>
            <li>
              <strong>Login</strong>: makes request to server with entered username and password
            </li>
            <li>
              <strong>Create Account</strong>: opens a new browser tab on backend server for user
              to create an account.  After the user requests an account on the backend server,
              an activation email is sent.
            </li>
            <li>
              <strong>Reset Password</strong>: opens a new browser tab on the backend server with
              form for user to request an email that contains a password reset link
            </li>
            <li>
              <strong>Forgot Username</strong>: opens a new modal with form that requests the
              username is sent to the specified email address
            </li>
          </ul>
          <hr />
          <h5>Application State</h5>
          <div>
            The <strong>Main</strong> component stores state and handles events that update the state
          </div>
          <pre><code className="javascript" id="state-object">
            {`  this.state = {
    files: {
      files: [],
      isLoading: true,
      errorMessage: ''
    },
    view: {
      filename: '',
      filerows: [],
      isLoading: true,
      page: 1,
      errorMessage: ''
    },
    login: {
      username: '',
      token: ''
    },
    location: this.props.location
  }`}</code></pre>
          <div>
            <hr />
            <h5>Application State Description</h5>
            <ul>
              <li>
                <strong>files</strong>
                <ul>
                  <li>
                    <strong>files</strong>: array of files uploaded on the server
                  </li>
                  <li>
                    <strong>isLoading</strong>: true when file list data is being requested from the server
                  </li>
                  <li>
                    <strong>errorMessage</strong>: status if the file list request was unsuccessful
                  </li>
                </ul>
              </li>
              <li>
                <strong>view</strong>
                <ul>
                  <li>
                    <strong>filename</strong>: file name selected in the Filelist component
                  </li>
                  <li>
                    <strong>filerows</strong>: array of strings for each row in the selected file
                  </li>
                  <li>
                    <strong>isLoading</strong>: true when file content data is being requested from the server
                  </li>
                  <li>
                    <strong>errorMessage</strong>: status if the file content request was unsuccessful
                  </li>
                </ul>
              </li>
              <li>
                <strong>login</strong>
                <ul>
                  <li>
                    <strong>username</strong>: user name entered in the Login modal dialog
                  </li>
                  <li>
                    <strong>token</strong>: authorization token retrieved from the login request
                    to the server
                  </li>
                </ul>
              </li>
              <li>
                <strong>location</strong>: URL pathname.  When the pathname is changed from
                /fileupload, the list of files is reloaded from the server.
              </li>
            </ul>
          </div>
          <hr />
          <h5>
            Event Handlers
          </h5>
          <ul>
            <li>
              <strong>handleUserToken</strong>: Updates state.login
            </li>
            <li>
              <strong>handleFileview</strong>: Requests file content data from the server then
              updates state.view.  A Readable Stream is used in the server request, so a large
              file is chunked into memory and extracted into rows of strings.
            </li>
            <li>
              <strong>handlePage</strong>: Files longer than 1000 rows are paginated into 1000
              row segments.  This updates the page of a file befing viewed.
            </li>
          </ul>
          <hr />
          <h5>
            Routes
          </h5>
          <h6>
            The <strong>Main</strong> component passes these state objects and event
            handlers to the components:
          </h6>
          <Table striped>
            <thead>
              <tr>
                <th>Route</th>
                <th>Component</th>
                <th>State</th>
                <th>Event Handlers</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>/home</td>
                <td>Home</td>
                <td>-</td>
                <td>-</td>
              </tr>
              <tr>
                <td>/filelist</td>
                <td>Filelist</td>
                <td>files</td>
                <td>handleFileview</td>
              </tr>
              <tr>
                <td>/fileupload</td>
                <td>Fileupload</td>
                <td>login</td>
                <td>-</td>
              </tr>
              <tr>
                <td>/fileviewer</td>
                <td>Fileviewer</td>
                <td>view</td>
                <td>handlePage</td>
              </tr>
              <tr>
                <td>/filestats</td>
                <td>Filestats</td>
                <td>view</td>
                <td>-</td>
              </tr>
            </tbody>
          </Table>
          <hr />
          <h5>Upcoming Features</h5>
          <p>
            These features are under consideration for addition:
          </p>
          <ul>
            <li>
              Displaying the username in the file list
            </li>
            <li>
              Allowing owner of a file the ability to delete it
            </li>
            <li>
              Allowing the owner of a file the ability to rename it
            </li>
          </ul>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}
