import React from 'react';
import { pageLength } from '../shared/pageLength';

export function Pagination(props) {

  const {page, pages, clickPage} = props;
  if (pages > 0) {
    return (
      <React.Fragment>
        <div className="mb-2">Files greater than {pageLength} rows are paginated</div>
        <nav aria-label="select page to view">
          <ul className="pagination pagination-sm d-flex flex-wrap">
            {
              new Array(pages)
                .fill(null)
                .map((p, i) => {
                  const i1 = i + 1;
                  const active = i1 === page ? 'active' : '';
                  return (
                    <li className={`page-item ${active}`} onClick={clickPage} key={i1} >
                      <a className={`page-link pagenum-${i1}`} href="#">{i1}</a>
                    </li>
                  )
                })
            }
          </ul>
        </nav>
      </React.Fragment>
    )
  } else {
    return null
  }
}