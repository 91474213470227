import React from 'react';
import {
  Breadcrumb, BreadcrumbItem,
  Card, CardBody, CardHeader, CardTitle, CardSubtitle,
  Button,
  Table,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { Loading } from "./Loading";


export default function Filestats(props) {

  const computeStats = (data) => {
    let yearsObj = {};
    let minYear = Number.MAX_VALUE;
    let maxYear = Number.MIN_VALUE;
    if (data.length === 0) {
      return {yearsObj, minYear, maxYear}
    }
    const headers = data[0].split(',');
    const dateIndex = headers.indexOf('date');
    if (dateIndex < 0) {
      return {yearsObj, minYear, maxYear}
    }
    yearsObj = data.reduce((acc, next) => {
      const values = next.split(',');
      // console.log(values, values);
      const date = values[dateIndex];
      if (date && /\d{2}\/\d{2}\/\d{4}/.test(date)) {
        const year = date.substr(6);
        minYear = Math.min(minYear, year);
        maxYear = Math.max(maxYear, year);
        acc[year] = acc[year] ? acc[year] + 1 : 1
      }
      return acc
    }, {});
    return {yearsObj, minYear, maxYear}
  };

  const createYearLabels = (stats) => {
    /*
     * Table will have the first column as a year divisible by 10
     * Column headers will be 0 through 9
     */
    const years = Object.keys(stats).filter(d => /^\d{4}$/.test(d));
    if (years.length === 0) {
      return []
    }
    const minLabel = Math.floor(Math.min(...years) / 10) * 10;
    const maxLabel = Math.floor(Math.max(...years) / 10) * 10;
    // console.log('yearLabels min max', minLabel, maxLabel);
    const length = ((maxLabel - minLabel) / 10) + 1;
    // console.log('yearLabels length', length);
    return new Array(length)
      .fill(null)
      .map((v, i) => minLabel + i * 10)
  };

  if (props.isLoading && props.filename) {
    return (
      <div className="container">
        <div className="row">
          <Loading/>
        </div>
      </div>
    );
    } else if (props.errorMessage) {
      return (
        <div className="container">
          <div className="row">
            <h4>{props.errorMessage}</h4>
          </div>
        </div>
      );
  } else {
    // console.log('filerows', props.filerows);
    const {yearsObj, minYear, maxYear} = computeStats(props.filerows);
    console.log('stats', yearsObj);
    const yearLabels = createYearLabels(yearsObj);
    // const minYear = Math.min(...yearLabels);
    // const maxYear = Math.max(...yearLabels);
    let viewFirst = ''
    if (!props.filename) {
      viewFirst = (
        <h6><small>File must be viewed before statistics are displayed</small></h6>
      )
    }

    return (
      <div className="container-fluid filestats">
        <div className="row row-header">
          <Breadcrumb>
            <BreadcrumbItem><Link to="/home">Home</Link></BreadcrumbItem>
            <BreadcrumbItem active>File Stats</BreadcrumbItem>
          </Breadcrumb>
          <div className="col-12 d-flex">
            <h3 className="d-inline mr-auto">File Stats</h3>
            <Link to="/filelist">
              <Button color="primary d-inline-block ml-auto">
                Select new file from list
              </Button>
            </Link>
          </div>
          <div className="col-12">
            { viewFirst }
            <hr />
          </div>
        </div>
        <div className="row row-content">
          <div className="col-12">
            { yearLabels.length > 0 && props.filename !== '' &&
            (
              <Card>
                <CardHeader>
                  <CardTitle>
                    <strong>{props.filename}</strong> Statistics
                  </CardTitle>
                  <CardSubtitle>
                    <p>Year is the first column + column header</p>
                    <p>Value is the number of rows with the date field equal to the year</p>
                  </CardSubtitle>
                </CardHeader>
                <CardBody>
                  <Table>
                    <thead><tr>
                      {
                        new Array(11).fill(null)
                          .map((v, i) => {
                            if (i === 0) {
                              return (
                                <td key={i}>Decade \ year</td>
                              )
                            } else {
                              return (
                                <td key={i}>{i - 1}</td>
                              )
                            }
                        })
                      }
                    </tr></thead>
                    <tbody>
                    {
                      yearLabels.map(y => {
                        return (
                          <tr key={y}>
                            {
                              new Array(11).fill(null).map((v, i) => {
                                if (i === 0) {
                                  return (
                                    <td className="decade" key={i}>{y}</td>
                                  )
                                } else {
                                  const year = y + i - 1;
                                  let value = yearsObj[year] || 0;
                                  let gray = '';
                                  // const gray = ((year < minYear) || (year > maxYear)) ? 'gray' : '';
                                  if ((year < minYear) || (year > maxYear)) {
                                    gray = 'gray';
                                    value = '';
                                  }
                                  return (
                                    <td key={i} className={gray}>{value}</td>
                                  )
                                }
                              })
                            }
                          </tr>
                        )
                      })
                    }
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            )}
          </div>
        </div>
      </div>
    )
  }
}
