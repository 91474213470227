import React, { Component } from 'react';
import { Switch, Route, withRouter } from 'react-router-dom'
import Header from './Header';
import fetchFilelist from "../shared/fetchFilelist";
import Home from './Home';
import Filelist from './Filelist';
import Fileupload from './Fileupload';
import Fileviewer from './Fileviewer';
import Filestats from './Filestats';
import { start } from '../shared/streamHttp';

class Main extends Component {

  constructor (props) {
    super(props);
    this.state = {
      location: this.props.location,
      files: {
        files: [],
        isLoading: true,
        errorMessage: ''
      },
      view: {
        filename: '',
        filerows: [],
        isLoading: true,
        page: 1,
        errorMessage: ''
      },
      login: {
        token: '',
        username: ''
      }
    };
    this.handleFileview = this.handleFileview.bind(this);
    this.handlePage = this.handlePage.bind(this);
    this.handleUserToken = this.handleUserToken.bind(this);
  }

  componentDidMount() {

    this.setState({
      files: {
        files: [],
        isLoading: true,
        errorMessage: ''
      }
    });

    fetchFilelist()
      .then(json => {
        this.setState({
          location: this.props.location,
          files: {
            isLoading: false,
            errorMessage: '',
            files: json
          }
        })
      })
      .catch(error => {
        this.setState({
          location: this.props.location,
          files: {
            files: [],
            isLoading: false,
            errorMessage: error.message
          }
        })
      })
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // console.log('componentDidUpdate');
    // console.log('prevProps', prevProps.location.pathname);
    // console.log('prevState', prevState.location.pathname);
    // console.log('props    ', this.props.location.pathname);
    // console.log('state    ', this.state.location.pathname);
    /*
     * Reload the filelist after uploading a file
     */
    if (
      (prevProps.location.pathname === '/fileupload')
      &&
      (this.props.location.pathname !== '/fileupload')
    ){
      // console.log('reload');
      this.componentDidMount();
    }
  }

  handleFileview(filename) {
    console.log(`handleFileview ${filename}`);
    this.setState({
        view: {
          filename,
          filerows: [],
          isLoading: true,
          page: 1
        }
      },
      () => {
        this.props.history.push(`/fileviewer`);
      }
    );
    const complete = (result) => {
      console.log('complete');
      this.setState({
        view: {
          ...this.state.view,
          isLoading: false,
          filerows: result
        }
      })
    };
    start(filename, complete)
      .catch(error => {
        this.setState({
          view: {
            ...this.state.view,
            isLoading: false,
            errorMessage: error.message
          }
        })
      });
    this.props.history.push(`/fileviewer`);
  }

  handlePage(page) {
    this.setState({
      view: {
        ...this.state.view,
        page
      }
    })
  }

  handleUserToken({username, token}) {
    this.setState({
      login: {
        token,
        username
      }
    })
  }

  render () {
    return (
      <div>
        <Header
          handleUserToken={this.handleUserToken}
          username={this.state.login.username}
        />
        <Switch location={this.props.location}>
          <Route path='/home' component={Home} />
          <Route path='/filelist'
                 component={() =>
                   <Filelist files={this.state.files} handleFileview={this.handleFileview} />}
          />
          <Route path='/fileupload'
                 component={() =>
                   <Fileupload token={this.state.login.token} />}
          />
          <Route path='/fileviewer'
                 component={() =>
                   <Fileviewer
                     filename={this.state.view.filename}
                     filerows={this.state.view.filerows}
                     isLoading={this.state.view.isLoading}
                     errorMessage={this.state.view.errorMessage}
                     page={this.state.view.page}
                     handlePage={this.handlePage}
                   />}
          />
          <Route path='/filestats'
                 component={() =>
                   <Filestats
                     filename={this.state.view.filename}
                     filerows={this.state.view.filerows}
                     isLoading={this.state.view.isLoading}
                     errorMessage={this.state.view.errorMessage}
                   />}
          />
          <Route path='/' component={Home} />
        </Switch>
      </div>
    )
  }
}

export default withRouter(Main);