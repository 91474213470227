import React from 'react';
import { Assignment } from "./Assignment";
import {Breadcrumb, BreadcrumbItem} from "reactstrap";
import {Link} from "react-router-dom";

export default function Home() {
  return (
    <div className="container">
      <div className="row row-header">
        <Breadcrumb>
          <BreadcrumbItem><Link to="/home">Home</Link></BreadcrumbItem>
        </Breadcrumb>
        <hr/>
      </div>
      <div className="row">
        <div className="col-12">
          <Assignment/>
        </div>
      </div>
    </div>
  )
}