import React, { Component } from 'react';
import { Navbar, NavbarBrand, Nav, NavbarToggler, Collapse, NavItem, Jumbotron,
  Button, Modal, ModalHeader, ModalBody,
  Form, FormGroup, Input, Label } from 'reactstrap';
import { NavLink } from "react-router-dom";
import { baseUrl } from "../shared/baseUrl";
import { get, post } from 'axios';

const timeout = 10000;

const config = {
  timeout,
  headers: {
    'content-type': 'application/json'
  }
};

export default class Header extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isNavOpen: false,
      isModalOpen: false,
      isUsernameModalOpen: false,
      message: '',
      usernameMessage: ''
    };
    this.toggleNav = this.toggleNav.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.toggleUsernameModal = this.toggleUsernameModal.bind(this);
    this.handleLogin = this.handleLogin.bind(this);
    this.handleUsername = this.handleUsername.bind(this);
    this.handleUsernameRequest = this.handleUsernameRequest.bind(this);
  }

  toggleNav() {
    this.setState({
      isNavOpen: !this.state.isNavOpen
    })
  }

  toggleModal() {
    this.setState({
      isModalOpen: !this.state.isModalOpen
    });
  }

  toggleUsernameModal() {
    this.setState({
      isUsernameModalOpen: !this.state.isUsernameModalOpen
    });
  }

  handleUsername() {
    this.setState({
      isModalOpen: false,
      isUsernameModalOpen: true
    });
  }

  handleUsernameRequest(event) {
    event.preventDefault();
    const email = this.email.value;
    const url = `${baseUrl}email_exists/${email}/`;
    get(url, config)
      .then(response => {
        console.log(response)
        if (response.data && response.data.exists === true) {
          this.setState({
            usernameMessage: 'Sending request to server.'
          });
          const url = `${baseUrl}request_username/${email}/`;
          get(url, config)
            .then(response => {
              if (response.status === 200) {
                this.setState({
                  usernameMessage: 'Check your email for your username then Log In.'
                });
              } else {
                this.setState({
                  usernameMessage: 'Email address not accepting email.'
                });
              }
            })
            .catch(err => {
              console.log(err);
              this.setState({
                usernameMessage: err.message
              })
            })

        } else {
          this.setState({
            usernameMessage: 'Email is not associated with an account.  Create a new account.'
          })
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({
          usernameMessage: err.message
        })
      })
  }

  handleLogin(event) {
    this.toggleModal();
    event.preventDefault();
    /*
     * Django API endpoint for Authorization Token
     */
    const url = `${baseUrl}api-token-auth/`;
    const data = {
      username: this.username.value,
      password: this.password.value
    };
    post(url, data, config)
      .then(response => {
        console.log(response.data);
        this.setState({
          message: ''
        });
        this.props.handleUserToken({
          username: data.username,
          token: response.data.token
        })
      })
      .catch(err => {
        let message = err.message;
        if (err.response.status === 400) {
          message = 'Incorrect username/password';
        }
        this.toggleModal();
        console.log(err);
        this.setState({
          message
        })
    })
  }

  render() {
    let logInOut;
    if (this.props.username) {
      logInOut = (
        <React.Fragment>
          <span className="username mr-2">Hi {this.props.username}!</span>
          <Button
            outline
            onClick={() => this.props.handleUserToken({username: '', token: ''})} >
            <span className="fa fa-sign-out fa-lg"></span> Logout
          </Button>
        </React.Fragment>
      )
    } else {
      logInOut = (
        <Button
          outline
          color="light"
          onClick={this.toggleModal}>
          <span className="fa fa-sign-in fa-lg"></span> Login
        </Button>
      )
    }
    return (
      <React.Fragment>
        <Navbar dark expand="md">
          <div className="container">
            <NavbarToggler onClick={this.toggleNav} />
            <NavbarBrand className="mr-auto" href="/">
              <img src={"/images/cloud-storage.png"} height="40" width="70"
                   alt="CSV Manager Cloud Storage" />
            </NavbarBrand>
            <Collapse isOpen={this.state.isNavOpen} navbar>
              <Nav navbar>
                <NavItem>
                  <NavLink className="nav-link" to="/home">
                    <span className="fa fa-home fa-lg"></span> Home
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink className="nav-link" to="/filelist">
                    <span className="fa fa-list fa-lg"></span> File List
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink className="nav-link" to="/fileupload">
                    <span className="fa fa-upload fa-lg"></span> File Upload
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink className="nav-link" to="/fileviewer">
                    <span className="fa fa-newspaper-o fa-lg"></span> File Viewer
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink className="nav-link" to="/filestats">
                    <span className="fa fa-table fa-lg"></span> File Stats
                  </NavLink>
                </NavItem>
              </Nav>
              <Nav className="ml-auto" navbar>
                <NavItem>
                  {logInOut}
                </NavItem>
              </Nav>
            </Collapse>
          </div>
        </Navbar>
        <Jumbotron>
          <div className="container">
            <div className="row row-header">
              <div className="col-12">
                <h1>CSV File Manager</h1>
                <p>Web Application for Storing, Retrieving, and Viewing CSV Files</p>
              </div>
            </div>
          </div>
        </Jumbotron>
        <Modal isOpen={this.state.isModalOpen} toggle={this.toggleModal}>
          <ModalHeader toggle={this.toggleModal}>Login</ModalHeader>
          <ModalBody>
            <Form onSubmit={this.handleLogin}>
              <FormGroup>
                <Label htmlFor="username">Username</Label>
                <Input type="text" id="username" name="username" autocomplete="username"
                       innerRef={(input) => this.username = input} />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="password">Password</Label>
                <Input type="password" id="password" name="password" autocomplete="current-password"
                       innerRef={(input) => this.password = input}  />
              </FormGroup>
              <FormGroup check>
                <Label check>
                  <Input type="checkbox" name="remember"
                         innerRef={(input) => this.remember = input}  />
                  Remember me
                </Label>
              </FormGroup>
              <div className="row">
                <div className="col-5 col-sm-5 mt-3">
                  <Button className="btn-block" type="submit" value="submit" color="primary">Login</Button>
                </div>
                <div className="col-5 col-sm-5 mt-3">
                  <a className="btn btn-success btn-block" href={`${baseUrl}accounts/register`} target="_blank">
                    Create Account
                  </a>
                </div>
                <div className="col-5 col-sm-5 mt-3">
                  <a className="btn btn-block btn-warning mr-4" href={`${baseUrl}accounts/password/reset`} target="_blank">
                    Reset Password
                  </a>
                </div>
                <div className="col-5 col-sm-5 mt-3">
                  <Button onClick={this.handleUsername} className="btn btn-block" color="info">
                    Forgot Username?
                  </Button>
                </div>
              </div>
              <div className="mt-2"><strong>{this.state.message}</strong></div>
            </Form>
          </ModalBody>
        </Modal>
        <Modal isOpen={this.state.isUsernameModalOpen} toggle={this.toggleUsernameModal}>
          <ModalHeader toggle={this.toggleUsernameModal}>Request username sent to your email?</ModalHeader>
          <ModalBody>
            <Form onSubmit={this.handleUsernameRequest}>
              <FormGroup>
                <Label htmlFor="email">Email</Label>
                <Input type="text" id="email" name="email" autocomplete="email"
                       innerRef={(input) => this.email = input} />
              </FormGroup>
              <Button className="mt-2" type="submit" value="submit" color="primary">Submit</Button>
              <div className="mt-4"><strong>{this.state.usernameMessage}</strong></div>
            </Form>
          </ModalBody>
        </Modal>
      </React.Fragment>
    )
  }
}