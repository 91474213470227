import TextDecoderPolyfill from './TextDecoder';
import { baseUrl } from "./baseUrl";

const TextDecoder = window.TextDecoder || TextDecoderPolyfill;


export function start(filename, complete) {
  const result = [];
  const url = `${baseUrl}media/${filename}`;
  return fetch(url)
    // Retrieve its body as ReadableStream
    .then(response => {
      const reader = response.body.getReader();
      return new ReadableStream({
        start(controller) {
          return pump();

          function pump() {
            return reader.read().then(({done, value}) => {
              // When no more data needs to be consumed, close the stream
              if (done) {
                controller.close();
                complete(result);
                return;
              }
              // Enqueue the next data chunk into our target stream
              controller.enqueue(value);
              return pump();
            });
          }
        }
      })
    })
    .then(stream => {
      const reader = stream.getReader();
      // let charsReceived = 0;
      let current = '';

      // read() returns a promise that resolves
      // when a value has been received
      reader.read().then(function processText({done, value}) {
        // Result objects contain two properties:
        // done  - true if the stream has already given you all its data.
        // value - some data. Always undefined when done is true.
        if (done) {
          console.log("Stream complete");
          if (current != '') {
            result.push(current);
          }
          return;
        }

        // charsReceived += value.length;
        const chunk = value;
        var string = current + new TextDecoder("utf-8").decode(chunk);
        const pieces = string.split('\n');
        current = pieces[pieces.length - 1];
        // console.log(string)

        result.push(...pieces.slice(0, -1));
        console.log(`rows: ${result.length}`)
        // notify();

        // Read some more, and call this function again
        return reader.read().then(processText);
      });
    })
}
