import React, { Component } from 'react';
import { Breadcrumb, BreadcrumbItem,
  Button,
  Form, FormGroup, Label, FormFeedback,
  Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import { baseUrl } from '../shared/baseUrl';
import { post } from 'axios';
import Progress from './Progress';
// 1 minute (60 second) timeout for post request
const timeout = 60000;

export default class Fileupload extends Component {

  constructor(props) {
    super(props);
    this.state = {
      csvfile: null,
      progress: 0,
      inProgress: false,
      message: null
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.files[0];
    const name = target.name;

    if (/\.csv$/i.test(value.name)) {
      this.setState({
        [name]: value,
        progress: 0,
        inProgress: false,
        message: null
      })
    } else {
      this.setState({
        csvfile: null,
        message: 'file name must end in .csv'
      })
    }

  }

  handleSubmit(event) {
    event.preventDefault();
    if (!this.props.token) {
      alert('You must login before uploading a file');
      return;
    }
    this.setState({
      inProgress: true
    });
    const formData = new FormData();
    formData.append('file', this.state.csvfile);
    const url = `${baseUrl}api/files/`;
    const config = {
      timeout,
      headers: {
        'content-type': 'multipart/form-data',
        'Authorization': `Token ${this.props.token}`
      },
      onUploadProgress: function(progressEvent) {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
        console.log(`upload progress: ${percentCompleted.toFixed(2)}%`);
        this.setState({
          progress: percentCompleted
        });
      }.bind(this)
    };
    post(url, formData, config)
      .then(response => {
        console.log('post response', response.data);
        if (response.data.name !== this.state.csvfile.name) {
          this.setState({
            message: `duplicate uploaded file renamed to ${response.data.name}`
          })
        }
      })
      .catch(err => {
        let message = err.message;
        /*
         * Check if Django has returned a custom error message
         */
        try {
          if (err.response && err.response.status === 400) {
            message = err.response.data.non_field_errors[0]
          }
        } catch (ex) {}
        this.setState({
          message,
          inProgress: false,
          progress: 0
        })
      })
  }

  render() {
    const errors = {csvfile: ''};
    const status = this.state.progress !== 100 ? 'Uploading' : 'Upload Complete';
    /*
     * Upload buttton is disabled if a file has not been chosen or the user is
     * not logged in
     */
    const disabled = !(this.state.csvfile && this.props.token) ? 'disabled' : '';
    const loginMessage = this.props.token ? '' : (
      <h6 className="my-4">
        <p>You must login before you can upload a file</p>
        <p>Request login credentials from "stefan0@stefan0.com"</p>
      </h6>
    )
    return (
      <div className="container">
        <div className="row">
          <Breadcrumb>
            <BreadcrumbItem><Link to="/home">Home</Link></BreadcrumbItem>
            <BreadcrumbItem active>Fileupload</BreadcrumbItem>
          </Breadcrumb>
          <div className="col-12">
            <h3>Fileupload</h3>
            <hr/>
          </div>
        </div>
        <div className="row">
          <div className="col-12 mb-4">
            <h4><em>Upload your CSV file</em></h4>
            {loginMessage}
          </div>
          <div className="col-12">
            <Form onSubmit={this.handleSubmit}>
              <FormGroup row>
                <Label htmlFor="csvfile" md={2}>Browse</Label>
                <Col md={10}>
                  <input type="file" name="csvfile" onChange={this.handleInputChange} className="form-control-file"/>
                  <FormFeedback>{errors.csvfile}</FormFeedback>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col md={{size: 10, offset: 2}}>
                  <Button type="submit" color="primary" disabled={disabled}>
                    Upload file
                  </Button>
                </Col>
              </FormGroup>
            </Form>
            {
              this.state.inProgress && (
                <>
                  <h6>{status}</h6>
                  <Progress progress={this.state.progress}/>
                </>
              )
            }
            {
              this.state.message && (
                <div className="mt-4">
                  <h6>{this.state.message}</h6>
                </div>
              )
            }
          </div>
        </div>
      </div>
    )
  }
}